import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function Home() {
    const userInfo = useSelector((state) => state.user.userInfo);

    return (
        <div>
            {!userInfo && <Navigate to={'/login'} replace />}
            {userInfo && <Navigate to={'/settings/personal'} replace />}
        </div>
    );
}

export default Home;
